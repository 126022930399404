/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import PageTransition from "gatsby-plugin-page-transitions"

import Header from "./header"
import "./layout.css"
import Footer from "./footer"
import ReactBreakpoints from "react-breakpoints"
import { BREAKPOINTS } from "../utils/config"

const Layout = ({ children, page }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          menuLinks {
            name
            link
          }
        }
      }
    }
  `)

  return (
    <ReactBreakpoints breakpoints={BREAKPOINTS}>
      <Header page={page} siteTitle={data.site.siteMetadata.title} menuLinks={data.site.siteMetadata.menuLinks}/>
      <main data-srgrid={""} className={page}>
        <PageTransition
          defaultStyle={{
            transition: "opacity 500ms cubic-bezier(0.47, 0, 0.75, 0.72)",
            opacity: .5,
          }}
          transitionStyles={{
            entering: { opacity: 1 },
            entered: { opacity: 1 },
            exiting: { opacity: .5 },
          }}
          transitionTime={500}>
          {children}
        </PageTransition>
      </main>
      <Footer/>
    </ReactBreakpoints>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
