import React from "react"
import Logo from "../assets/Logo.svg"
import { Link } from "gatsby"
import Madeby from "../assets/madeby.svg"
import Arrow from "../assets/arrow-top.svg"
import { withBreakpoints } from "react-breakpoints"

const Footer = ({ breakpoints, currentBreakpoint }) => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    })
  }
  return (
    <footer className={"background--dark footer"}>
      <div data-srgrid-row="">
        <div data-srgrid-col="xs-24 m-12">
          <div className="footer--alignment">
            <div data-srgrid-row="" className={"footer__one"}>
              <div data-srgrid-col="xs-20 m-12 l-8">
                <Logo className={"logo"}/>
              </div>
              {(breakpoints[currentBreakpoint] >= breakpoints.l) || (breakpoints[currentBreakpoint] <= breakpoints.s) ? (
                <div data-srgrid-col="xs-s-24 l-offset-1">
                  <p className={"p p--xs footer__claim"}>
                    Da wo's nur Brot gibt.
                  </p>
                </div>
              ) : null}
              {(breakpoints[currentBreakpoint] === breakpoints.m) ? (
                <div data-srgrid-col="m-24" className={"one__social"}>
                  <a href={"https://instagram.com"} rel="noopener noreferrer" target="_blank"
                     className={"h2"}>Instagram</a>
                  <br/>
                  <a href={"https://facebook.com"} rel="noopener noreferrer" target="_blank"
                     className={"h2"}>Facebook</a>
                </div>
              ) : null}
            </div>
            {(breakpoints[currentBreakpoint] > breakpoints.s) ? (
              <div data-srgrid-row="" className="footer__one">
                <div data-srgrid-col="xs-24 l-offset-9" className={"footer__legal"}>
                  <p className={"fira-mono fira-mono--xs"}>&copy;2020 Max Kugel</p>
                  <p className={"fira-mono fira-mono--xs"}><Link to={"/impressum"}
                                                                 className={"_td-none"}>Impressum</Link>
                  </p>
                  <p className={"fira-mono fira-mono--xs"}><Link to={"/impressum"}
                                                                 className={"_td-none"}>Datenschutzerklärung</Link></p>
                </div>
              </div>
            ) : null}
          </div>
        </div>
        <div data-srgrid-col="xs-24 m-10 m-offset-2 l-9 l-offset-3">
          {(breakpoints[currentBreakpoint] === breakpoints.m) ? (
            <div data-srgrid-row="" className={"footer__row"}>
              <div data-srgrid-col="xs-24">
                <p className={"p p--xs footer__claim"}>
                  Da wo's nur Brot gibt.
                </p>
              </div>
            </div>
          ) : null}
          <div data-srgrid-row="" className={"footer__row"}>
            <div data-srgrid-col="xs-24 l-12">
              <span className="h2">Öffnungszeiten</span>
              <p className={"p p--xs"}>
                Di-Fr: 10:00 - 18:30 Uhr <br/>
                Sa: 08:00 - 14:00 Uhr
              </p>
            </div>

            {breakpoints[currentBreakpoint] >= breakpoints.l ? (
              <div data-srgrid-col="xs-24 m-12">
                <a href={"https://instagram.com"} rel="noopener noreferrer" target="_blank"
                   className={"h2 footer__social-item"}>Instagram</a>
                <a href={"https://facebook.com"} rel="noopener noreferrer" target="_blank"
                   className={"h2 footer__social-item"}>Facebook</a>
              </div>
            ) : ""}
          </div>
          <div data-srgrid-row="" className={"footer__row"}>
            <div data-srgrid-col="xs-24 l-12">
              <span className="h2">Wo wir sind</span>
              <p className={"p p--xs"}>
                Bonner Talweg 34 <br/>
                53113 Bonn
              </p>
            </div>
          </div>
          <div data-srgrid-row="m-bottom" className={"footer__row"}>
            <div data-srgrid-col="xs-24 l-12" className={"row__contact"}>
              <span className="h2">Kontakt</span>
              <p className={"p p--xs"}>
                <a href={"tel:022885083675"}>0228 850 836 75</a> <br/>
                <a href={"mailto:hallo@maxkugel.de"}>hallo@maxkugel.de</a>
              </p>
            </div>
            {breakpoints[currentBreakpoint] < breakpoints.m ? (
              <div data-srgrid-col="xs-24 l-12" className={"row__social"}>
                <a href={"https://instagram.com"} rel="noopener noreferrer" target="_blank"
                   className={"h2"}>Instagram</a>
                <br/>
                <a href={"https://facebook.com"} rel="noopener noreferrer" target="_blank" className={"h2"}>Facebook</a>
              </div>
            ) : null}

            {(breakpoints[currentBreakpoint] <= breakpoints.s) ? (
              <div data-srgrid-col="xs-24" className={"footer__legal"}>
                <p className={"fira-mono fira-mono--xs"}>&copy;2020 Max Kugel</p>
                <p className={"fira-mono fira-mono--xs"}><Link to={"/impressum"} className={"_td-none"}>Impressum</Link>
                </p>
                <p className={"fira-mono fira-mono--xs"}><Link to={"/impressum"}
                                                               className={"_td-none"}>Datenschutzerklärung</Link></p>
              </div>
            ) : null}
            <div data-srgrid-col="xs-24 l-12" className={"footer__made-by fira-mono fira-mono--xs"}>
              Website by <Madeby/>
              <button className={"footer__scroll-to-top"}
                    onClick={() => scrollToTop()}
                    onKeyPress={() => scrollToTop()}>
                <Arrow/>
              </button>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default withBreakpoints(Footer)