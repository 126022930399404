import React from "react"
import Img from "gatsby-image"

const TextImage = ({ headline, children, source, source_position }) => {
  return (
    <>
      {source_position === "top" && (
        <div data-srgrid-row="">
          <div data-srgrid-col="xs-24">
            <Img fluid={source}/>
          </div>
        </div>
      )}
      <div className={"text-image background--dark"}>
        <div data-srgrid-row="">
          <div data-srgrid-col="xs-24 m-12">
            {headline && (
              <p className={"h1--max text-image__headline"}>{headline}</p>
            )}
          </div>
          <div data-srgrid-col="xs-24 m-12">
            {children}
          </div>
        </div>
      </div>
      {source_position === "bottom" && (
        <div data-srgrid-row="">
          <div data-srgrid-col="xs-24">
            <Img fluid={source}/>
          </div>
        </div>
      )}
    </>
  )
}

export default TextImage