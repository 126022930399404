import React, { Component } from "react"
import { Link } from "gatsby"
import * as PropTypes from "prop-types"

class Navigation extends Component {
  state = {
    hiddenMenu: false,
  }

  toggleMenu = () => {
    this.setState({ hiddenMenu: !this.state.hiddenMenu })
  }

  render() {
    const { menuLinks, page } = this.props
    return (
      <nav className={page === "impressum" || page === "error" ? "nav--black" : ""}>
        <span data-srgrid-hide="m-xl" onClick={() => this.toggleMenu()}
              onKeyPress={() => this.toggleMenu()}
              role="button"
              tabIndex="0"
        >Menü</span>
        <ul className={`${this.state.hiddenMenu ? "nav--active" : "nav--inactive"}`}>
          {menuLinks.map((menuLink) => (
            <li key={menuLink.name}>
              <Link to={menuLink.link}
              >{menuLink.name}</Link>
            </li>
          ))}
        </ul>
      </nav>
    )
  }
}

Navigation.propTypes = { menuLinks: PropTypes.any }

export default Navigation