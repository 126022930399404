import React from "react"
import Img from "gatsby-image"
import { InView } from "react-intersection-observer"
import FadeIn from "react-fade-in"
import * as PropTypes from "prop-types"
import { withBreakpoints } from "react-breakpoints"

class ImageColumn extends React.Component {
  state = {
    rightInView: false,
    leftInView: false,
  }

  toggleInView = (inView, entry) => {
    this.setState({ leftInView: inView, rightInView: inView })
  }

  render() {
    let { source_left, source_right, figcaption_left, figcaption_right, breakpoints, currentBreakpoint } = this.props
    return (
      <div className={"image-column"} data-srgrid-row={"center"}>
        <div data-srgrid-col="xs-24 m-10 l-7">
          <InView as="div" onChange={(inView, entry) => this.toggleInView()}>
            {this.state.rightInView && (breakpoints[currentBreakpoint] >= breakpoints.m) ? (
              <FadeIn delay={65} transitionDuration={800}>
                <figure>
                  <Img fluid={source_left} className={"image-column__image-left"}/>
                  {figcaption_left && (
                    <figcaption className="fira-mono fira-mono--xs _ta-left"
                                dangerouslySetInnerHTML={{ __html: figcaption_left }}/>
                  )}
                </figure>
              </FadeIn>
            ) : (
              <figure>
                <Img fluid={source_left} className={"image-column__image-left"}/>
                {figcaption_left && (
                  <figcaption className="fira-mono fira-mono--xs _ta-left"
                              dangerouslySetInnerHTML={{ __html: figcaption_left }}/>
                )}
              </figure>
            )}
          </InView>
        </div>
        <div data-srgrid-col="xs-24 m-10 l-7 l-offset-2">
          <InView as="div" onChange={(inView, entry) => this.setState({ rightInView: inView })}>
            {this.state.rightInView && (breakpoints[currentBreakpoint] >= breakpoints.m) ? (
              <FadeIn delay={200}>
                <figure>
                  <Img fluid={source_right} className={"image-column__image-right"}/>
                  {figcaption_right && (
                    <figcaption className="fira-mono fira-mono--xs _ta-left"
                                dangerouslySetInnerHTML={{ __html: figcaption_right }}/>
                  )}
                </figure>
              </FadeIn>
            ) : (
              <figure>
                <Img fluid={source_right} className={"image-column__image-right"}/>
                {figcaption_right && (
                  <figcaption className="fira-mono fira-mono--xs _ta-left"
                              dangerouslySetInnerHTML={{ __html: figcaption_right }}/>
                )}
              </figure>
            )}
          </InView>
        </div>
      </div>
    )
  }
}

ImageColumn.propTypes = {
  source_left: PropTypes.any,
  source_right: PropTypes.any,
  figcaption_left: PropTypes.any,
  figcaption_right: PropTypes.any,
}

export default withBreakpoints(ImageColumn)