import React from "react"
import Img from "gatsby-image"

const HeroImage = ({ source, text, text_position, wrapper, text_padding }) => (
  <div className={`hero hero--image${wrapper ? ' hero--wrapper' : ''}`}>
    {((text && text_position === "top-left") || (text && text_position === "top-right")) && (
      <div data-srgrid-row="">
        <div data-srgrid-col="xs-24">
          <div className={`hero__text ${text_padding === false && 'hero__text--no-padding'}`}>
          <p className={"fira-mono fira-mono--xs"} dangerouslySetInnerHTML={{ __html: text }}/>
          </div>
        </div>
      </div>
    )}
    <Img fluid={source}/>
    {((text && text_position === "bottom-left") || (text && text_position === "bottom-right")) && (
      <div data-srgrid-row="">
        <div data-srgrid-col="xs-24">
          <div className={`hero__text ${text_padding === false && 'hero__text--no-padding'}`}>
            <p className={"fira-mono fira-mono--xs"} dangerouslySetInnerHTML={{ __html: text }}/>
          </div>
        </div>
      </div>
    )}
  </div>
)

export default HeroImage