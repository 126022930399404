import { Link } from "gatsby"
import React, { Component } from "react"
import Logo from "../assets/Logo.svg"
import LogoBlack from "../assets/Logo--black.svg"
import Navigation from "./navigation"

class Header extends Component {
  constructor(props) {
    super(props)

    this.state = {
      show: true,
      scrollPos: 0,
    }

    this.handleScroll = this.handleScroll.bind(this)
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll)
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll)
  }

  handleScroll() {
    this.setState({
      scrollPos: document.body.getBoundingClientRect().top,
      show: document.body.getBoundingClientRect().top === 0,
    })
  }

  render() {
    const { menuLinks, page } = this.props
    return (
      <header className={`${this.state.show ? "active" : "hidden"}`}>
        <div data-srgrid-row="l-middle">
          <div data-srgrid-col="xs-12 l-7">
            <Link to={"/"}>
              {page === "impressum" || page === "error" ? (
                <LogoBlack className={"logo"}/>
              ) : (
                <Logo className={"logo"}/>
              )}
            </Link>
          </div>
          <div data-srgrid-col="xs-s-12">
            <Navigation menuLinks={menuLinks} page={page}/>
          </div>
        </div>
      </header>
    )
  }
}

export default Header
